import React from "react";
import { Layout } from "antd";
import NavigationBar from "./Components/mainNavigationBar";
import AppFooter from "./Components/Footer";
import Card from "./Components/Card";
import founderPhoto from "./team_pics/jagjit.jpg";
import christopher_mason from "./team_pics/Mason.jpg";
import raul from "./team_pics/raul.jpg";
import sunny from "./team_pics/sunny.jpg";
import photo3 from "./team_pics/andrew.jpg";
import anjali_photo from "./team_pics/anjali.png";
import kaja from "./team_pics/kaja_photo.jpeg";
import arun_goyal from "./team_pics/arun_goyal.jpeg";
import vaibhav_jaiswal from "./team_pics/vaibhav_photo.png";
import jayasuriya from "./team_pics/jayasuriya.png";
const { Header, Content, Footer } = Layout;

export const About = () => (
  <>
    <Layout className="flex flex-col font-futura min-h-screen bg-white">
      <Header className="flex w-full p-0 bg-white">
        <NavigationBar />
      </Header>
      <Content className="bg-white ">
        <div className="bg-white w-full flex flex-col flex-grow justify-center items-center">
          <h1 className="font-proxima-nova font-thin text-5xl pt-20 text-black text-center">
            Meet Our Team
          </h1>
          <p className=" font-proxima-nova text-2xl font-thin mt-10 mx-20 text-center">
            Designing And Building The Ecosystem
          </p>
        </div>
        <div className="bg-white w-full flex-grow min-h-full mt-10 mb-20 grid md:grid-cols-2 lg:grid-cols-4 grid-cols-1 justify-start items-start">
          <div className="bg-white flex items-center justify-center">
            <Card
              imageSrc={photo3}
              name="Andrew Blumberg"
              position="Advisor @Flahybase"
              text="Herbert and Florence Irving Professor of Cancer Data Research at Columbia University Irving Medical Center"
            />
          </div>
          <div className="bg-white flex items-center justify-center">
            <Card
              imageSrc={anjali_photo}
              name="Anjali Barnwal"
              position="Visiting Scientist @Flahybase"
              text="Post-Doctoral Associate Duke University Medical Center"
            />
          </div>
          <div className="bg-white flex items-center justify-center">
            <Card
              imageSrc={arun_goyal}
              name="Arun Goyal"
              position="Director @Flahybase India"
              text="Ex. Vice Chairman of Trident Group"
            />
          </div>
          <div className="bg-white flex items-center justify-center">
            <Card
              imageSrc={christopher_mason}
              name="Christopher Mason"
              position="Advisor @Flahybase"
              text="Professor of Physiology and Biophysics at Weill Cornell Medicine"
            />
          </div>
          <div className="bg-white flex items-center justify-center">
            <Card
              imageSrc={founderPhoto}
              name="Jagjit Singh"
              position="CEO & Biostatistician @Flahybase"
              text="MSc Biostatistics Columbia University"
            />
          </div>
          <div className="bg-white flex items-center justify-center">
            <Card
              imageSrc={jayasuriya}
              name="Jayasuriya JM"
              position="Computational Biologist @Flahybase"
              text="MSc. Life Sciences National Institue of Technology"
            />
          </div>
          <div className="bg-white flex items-center justify-center">
            <Card
              imageSrc={kaja}
              name="Kaja Wasik"
              position="Advisor @Flahybase"
              text="Co-Founder Variant Bio and Gencove"
            />
          </div>
          <div className="bg-white flex items-center justify-center">
            <Card
              imageSrc={raul}
              name="Raúl Rabadán"
              position="Advisor @Flahybase"
              text="Professor of Systems Biology at Columbia University Irving Medical Center"
            />
          </div>
          <div className="bg-white flex items-center justify-center">
            <Card
              imageSrc={vaibhav_jaiswal}
              name="Vaibhav Jaiswal"
              position="Computational Biologist @Flahybase"
              text="MSc. Biological Sciences Indian Institute Of Technology"
            />
          </div>
        </div>
      </Content>
      <Footer className="p-0 bg-white">
        <AppFooter />
      </Footer>
    </Layout>
  </>
);
