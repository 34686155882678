import React from "react";
import { Layout } from "antd";
import NavigationBar from "./Components/mainNavigationBar";
import AppFooter from "./Components/Footer";
const { Header, Content, Footer } = Layout;

export const ContactUs = () => (
  <>
    <Layout className="flex flex-col font-futura min-h-screen bg-white">
      <Header className="flex w-full p-0 bg-white">
        <NavigationBar />
      </Header>
      <Content className="bg-white ">
        <div className="bg-white w-full flex flex-col flex-grow justify-center items-center">
          <h1 className="font-proxima-nova text-5xl pt-20 text-black">
            Contact Us
          </h1>
          <p className=" font-proxima-nova text-2xl font-thin mt-10 mx-20 text-center">
            Please email us at contact@flahybase.com
          </p>
          <p className="  font-proxima-nova text-2xl font-thin mt-10 mx-20 text-center">
            Mailing address
          </p>
          <p className="  font-proxima-nova text-2xl font-normal mt-10 mx-20 text-center">
            United States - 548 Market St PMB 32531, San Francisco, California 94104
	  </p>
          <p className="  font-proxima-nova text-2xl font-normal mt-10 mx-20 text-center">
            India - 4th Floor WL2, Godrej Eternia, Wing A, Plot no. 70, Industrial Area Phase 1, Chandigarh, 160002
          </p>
        </div>
      </Content>
      <Footer className="p-0 bg-white">
        <AppFooter />
      </Footer>
    </Layout>
  </>
);
